import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../../layouts'
import LargeHero from '../../../components/elements/largeHero'
import { Body, SizedText, Canon } from '../../../components/elements/typography'
import Grid from '../../../components/elements/grid'
import { Link } from 'gatsby'
import SEO from "../../../components/elements/seo"


const PolicyTile = ({ to, title, children }) => {
  return (
  <Link to={to} css={{
    textAlign: `left`,
    backgroundColor: `hsla(0,0%,100%,.75)`,
    boxShadow: `0 1px 2px rgba(0,0,0,0.15)`,
    borderRadius: `16px`,
    padding: `1.5rem`,
    margin: `0.25rem`,
    color: `#000`,
    textDecoration: `none`,
    transition: `margin 0.5s, background-color 0.5s`,
    '&:hover': {
      margin: `0`,
      backgroundColor: `#fff`
    }
  }}>
    <div>
      <SizedText as="h2" smallSize="1.125" largeSize="1.424" css={{marginBottom: `1rem`, color: `#3498db`}}>{title}</SizedText>
      <Body>{children}</Body>
    </div>
  </Link>
  )
}


const PolicyIndexPage = () => {
  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>

      <SEO
        title="TCB Trust Center | Policies and Disclosures"
        description="Our commitment to you is fair and responsible banking. Take the time to read through the policies and disclosures so you are fully aware of your rights and responsibilities."
        location="/about/policies"
        ogImg='/images/social-graph/the-claxton-bank.jpg'
      />  

      <LargeHero.Container
        padding="3rem 0 4rem 0"
        background={{
          backgroundImage: `url(/images/background-deltas.jpg)`, 
          backgroundRepeat: `no-repeat`, 
          backgroundAttachment: `scroll`, 
          backgroundPosition: `top left`, 
          backgroundSize: `cover`,
        }}
        breadcrumbLinks={[
          { path: "/", text: "Home" },
          { path: "/about/", text: "About Us" },
          { path: "/about/policies/", text: "Policies" },
        ]}
        styles={{
          paddingBottom: `5rem`,
          overflow: `hidden`,
          '.breadcrumbs, .breadcrumbs [aria-current="page"]': {color: `#fff`},
          '.heroContent': { paddingBottom: `3rem`},
          '@media (min-width: 680px)': {
            '.heroContent': { width: `calc(72% - 1rem)`, marginBottom: `0`},
            '.heroImage': { width: `calc(40% - 1rem) !important`,},
          }
        }}
      >
        <LargeHero.Content styles={{paddingTop: `1rem`, color: `#fff`}}>
          <LargeHero.Eyebrow text="About Us" styles={{borderColor: `#fff !important`}}/>
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="4.624">Policies & Disclosures</SizedText>
          </h1>
        </LargeHero.Content>
        <LargeHero.Image >
          <StaticImage 
              css={{
                '@media (min-width: 680px)': { display: 'none !important' }, 
                '@media (min-width: 1300px)': { display: 'block !important' } 
              }} 
              src="../../../images/policies.png" 
              alt=""
            />
            <StaticImage 
              css={{
                display: `none !important`,  
                '@media (min-width: 680px)': { display: 'block !important' }, 
                '@media (min-width: 1300px)': { display: 'none !important' } 
              }} 
              src="../../../images/policies_cropped.png" 
              alt=""
            />
        </LargeHero.Image>
      </LargeHero.Container>

      <Grid.FullWidthSection>
        <Grid.Block start={[2,2,2,2,2,3]} span={[12,12,12,12,12,10]} css={{padding: `6rem 0`}}>
            <div css={{
              display: `grid`,
              justifyContent: `space-between`,
              gridGap: `2rem`,
              gridTemplateColumns: `repeat(1, 1fr)`,
              '@media (min-width: 680px)': { gridTemplateColumns: `repeat(2, 1fr)`, }, 
              '@media (min-width: 1000px)': { 
                gridTemplateColumns: `repeat(3, 1fr)`,
              }, 
            }}>
              <PolicyTile to="/about/policies/privacy-policy" title="Privacy Policy">
                We are committed to maintaining your privacy and trust. Keeping your personal information safe is our number one priority. Find out how we collect, use, share, and protect your information.
              </PolicyTile>
              <PolicyTile to="/about/policies/fair-lending-policy" title="Fair Lending Policy">
                TCB is committed to providing credit opportunities in the communities in which we conduct business. We recognize our responsibility for ensuring members of these communities receive equal 
                access to our products and services, which is why it is the policy of The Claxton Bank to comply both to the letter and spirit of fair and responsible lending laws.
              </PolicyTile>
              <PolicyTile to="/about/policies/safe-act" title="SAFE Act">
                The Secure and Fair Enforcement for Mortgage Licensing Act (SAFE Act) is a federal law designed to enhance consumer protection and reduce fraud in the mortgage lending industry. 
              </PolicyTile>
              <PolicyTile to="/about/policies/patriot-act" title="US PATRIOT Act">
                To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify and record information that identifies 
                each person who opens an account or creates a new customer relationship with our bank.
              </PolicyTile>
              <PolicyTile to="/about/policies/fdic-coverage" title="FDIC Insurance Coverage">
                Your The Claxton Bank deposits are fully protected up to the standard deposit insurance amount by the Federal Deposit Insurance Corporation (FDIC). Lean how to maximize your coverage.  
              </PolicyTile>
              <PolicyTile to="/about/policies/security" title="Security Policy">
                Keeping customer information secure Is our highest priority. Learn how The Claxton Bank takes every precaution to make sure your personal information remains safe.
              </PolicyTile>
              <PolicyTile to="/about/policies/terms-of-use" title="Terms & Conditions">
              These terms govern the use of this website. Please read them carefully before accessing the site. If you do not agree with these terms, do not access the website. By accessing the website 
              or any of its pages, you agree to be bound by these terms of use.
              </PolicyTile>
              <PolicyTile to="/about/policies/accessibility" title="Accessibility">
              The Claxton Bank is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone and applying the relevant accessibility standards to ensure we provide equal access to all of our users.
              </PolicyTile>
            </div>
        </Grid.Block>
      </Grid.FullWidthSection>
    </Layout> 
  )
}

export default PolicyIndexPage
